var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-html2canvas-ignore": "true" } },
    [
      _c(
        "v-footer",
        {
          staticClass: "white--text trade-blue font-weight-light",
          attrs: { app: "", color: "#1f3c5f" }
        },
        [
          _c("span", { staticClass: "ml-5 caption" }, [
            _vm._v("© " + _vm._s(new Date().getFullYear()) + " ")
          ]),
          _c("span", { staticClass: "ml-3 caption d-none d-sm-flex" }, [
            _vm._v(" v" + _vm._s(_vm.version))
          ]),
          this.$store.state.app.callsPending > 0
            ? _c("span", { staticClass: "yellow--text caption" }, [
                _vm._v(" [" + _vm._s(this.$store.state.app.callsPending) + "]")
              ])
            : _vm._e(),
          _c("v-spacer"),
          _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .combinations.markets_count > 0 ||
          _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
            .combinations.products_count > 0
            ? [
                _c(
                  "span",
                  { staticClass: "yellow--text caption" },
                  [
                    _c("v-icon", { staticClass: "yellow--text caption" }, [
                      _vm._v("mdi-earth")
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters
                            .META.combinations.markets_count
                        ) +
                        " "
                    )
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "yellow--text caption" },
                  [
                    _c("v-icon", { staticClass: "yellow--text caption" }, [
                      _vm._v("mdi-dolly")
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.vFmt(
                            _vm.$store.state.tradeDSM.dsm_filter_setttings
                              .Filters.META.combinations.products_count,
                            0
                          )
                        ) +
                        " "
                    )
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "yellow--text caption" },
                  [
                    _c("v-icon", { staticClass: "yellow--text caption" }, [
                      _vm._v("mdi-sigma")
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.vFmt(
                            _vm.$store.state.tradeDSM.dsm_filter_setttings
                              .Filters.META.combinations.combinations,
                            0
                          )
                        ) +
                        " "
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .markets.C_Code.length === 1
            ? _c("span", { staticClass: "yellow--text caption ml-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.codeToMarket(
                      _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters
                        .META.selections.markets.C_Code[0]
                    )
                  )
                )
              ])
            : _vm._e(),
          _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.selections
            .products.HS6_Code.length === 1
            ? _c("span", { staticClass: "yellow--text caption ml-3" }, [
                _vm._v(
                  "[" +
                    _vm._s(_vm.single_product) +
                    "] - " +
                    _vm._s(
                      this.hs6_codeToDescription(this.single_product).substring(
                        0,
                        120
                      )
                    ) +
                    "..."
                )
              ])
            : _vm._e(),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-spacer"),
          _vm.$store.state.excel_report_generation_in_progress === true
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                { staticClass: "iconPM yellow--text mr-2" },
                                on
                              ),
                              [_vm._v("mdi-microsoft-excel")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2805047086
                  )
                },
                [_c("span", [_vm._v("Excel document being prepared.")])]
              )
            : _vm._e(),
          _vm.$store.state.word_report_generation_in_progress === true
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                { staticClass: "iconPM yellow--text mr-2" },
                                on
                              ),
                              [_vm._v("mdi-microsoft-word")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1995116471
                  )
                },
                [_c("span", [_vm._v("Word document being prepared")])]
              )
            : _vm._e(),
          _vm.$store.state.email_report_generation_in_progress === true
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                { staticClass: "iconPM yellow--text mr-2" },
                                on
                              ),
                              [_vm._v("mdi-email")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1663887340
                  )
                },
                [
                  _c("span", [
                    _vm._v(
                      "Document being prepared and will be deliverd via email"
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c("span", { staticClass: "yellow--text mr-6" }, [
            _vm._v(
              " |" + _vm._s(_vm.$store.state.app.user_server_message) + "| "
            )
          ]),
          _c("span"),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META
                    .report_type_to_generate
                ) +
                " "
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }