import localforage from 'localforage';
import swal from 'sweetalert2';

export async function handle_crm_url_params(urlParams) {
  // Check if the request is coming from 'crm'
  if (urlParams.get('requested_by') === 'crm') {
    let crmRequest = {};

    // Iterate over the entries in URLSearchParams and assign them to the object
    urlParams.forEach((value, key) => {
      crmRequest[key] = value;
    });
    console.table(crmRequest);
    // Store the crmRequest object in localStorage
    await localforage.setItem('crm', crmRequest);
  } else {
    // If not a CRM request, store null
    clear_crm_action_que();
  }
}

export async function crm_action_available() {
  return localforage
    .getItem('crm')
    .then(value => {
      return value !== null && value !== undefined;
    })
    .catch(() => {
      return false; // Return false if an error occurs during retrieval
    });
}

export function clear_crm_action_que() {
  localforage.setItem('crm', null);
}

export async function crm_action() {
  if (crm_action_available()) {
    return await localforage.getItem('crm');
  } else {
    return null;
  }
}

export async function crm_action_window() {
  let action = await crm_action();
  if (action) {
    // Return the promise from the swal.fire so the function waits for the result
    swal.fire({
      title: 'There was an action request from the CRM system',
      html: `
        <table style="text-align: left; font-family: 'Roboto', sans-serif;">
          <tr>
            <td><strong>Request for:</strong></td>
            <td>${action['company_name']}</td>
          </tr>
          <tr>
            <td><strong>Address:</strong></td>
            <td>${action['company_address']}</td>
          </tr>
          <tr>
            <td><strong>Report requested for</strong></td>
            <td>${action['company_contact_first_name']} ${
        action['company_contact_last_name']
      }</td>
          <tr>
            <td><strong>Action requested by</strong></td>
            <td>${action['process_createdby_user_name']}</td>
          </tr>
             <td><strong>Process ID</strong></td>
            <td>${action['process_id']}</td>
          </tr>
          </tr>
        </table>`,
      icon: 'success',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Action',
    });
  } else {
    swal.fire({
      title: 'There is no actions in the CRM Que',
    });
  }
}

export async function clear_crm_request() {
  await localforage.setItem('crm', null);
}
