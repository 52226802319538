var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.user.logged_in
    ? _c(
        "v-navigation-drawer",
        {
          staticClass: "trade-blue-text outline grey lighten-4",
          attrs: {
            app: "",
            clipped: "",
            "mini-variant": "",
            "mini-variant-width": "500",
            right: "",
            temporary: ""
          },
          model: {
            value: _vm.$store.state.app.drawerRight,
            callback: function($$v) {
              _vm.$set(_vm.$store.state.app, "drawerRight", $$v)
            },
            expression: "$store.state.app.drawerRight"
          }
        },
        [
          typeof _vm.user !== "undefined"
            ? _c(
                "div",
                [
                  _c(
                    "v-layout",
                    { staticClass: "justify-end mb-0", attrs: { row: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "v-btn--outlined trade-blue white--text mr-10 mt-2 justify-end rounded",
                          attrs: { elevation: "0", small: "" },
                          on: {
                            click: function($event) {
                              _vm.$store.state.app.drawerRight = false
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-cancel")
                          ]),
                          _vm._v(" Close ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      staticClass: "justify-center mt-0 pa-0",
                      attrs: { row: "" }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "grey lighten-4",
                          attrs: { elevation: "0" }
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "trade-blue-text align-center ml-4 pa-0"
                            },
                            [
                              _vm._v("User Info "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "caption ml-2",
                                  attrs: { small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.send_mail()
                                    }
                                  }
                                },
                                [_vm._v(" (Update)")]
                              )
                            ],
                            1
                          ),
                          true
                            ? _c(
                                "v-card-text",
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-1 caption",
                                    attrs: {
                                      headers: [
                                        {
                                          text: "Setting",
                                          value: "item",
                                          class: "trade-blue"
                                        },
                                        {
                                          text: "Value",
                                          value: "value",
                                          class: "trade-blue"
                                        }
                                      ],
                                      items: _vm.user_items,
                                      "items-per-page": 5,
                                      dark: "",
                                      dense: "",
                                      "disable-filtering": "",
                                      "disable-pagination": "",
                                      "disable-sort": "",
                                      "hide-default-footer": "",
                                      "hide-default-header": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              item.item === "Mobile"
                                                ? _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "grey darken-3"
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.item)
                                                        )
                                                      ]),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "mt-2 mb-0 pa-0",
                                                            attrs: {
                                                              id:
                                                                "mobile_entry",
                                                              dense: "",
                                                              filled: "",
                                                              label: "Edit",
                                                              outlined: ""
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.update_field(
                                                                  _vm.user[
                                                                    "profile_pk"
                                                                  ],
                                                                  "cell_phone",
                                                                  $event
                                                                )
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.user.fields
                                                                  .cell_phone,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.user
                                                                    .fields,
                                                                  "cell_phone",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "user.fields.cell_phone"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                : item.item === "Include_Mobile"
                                                ? [
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticClass:
                                                          "grey darken-3 ma-0 pa-0"
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "mt-2 pt-0 pb-0 mb-0"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Include mobile in reports:"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c("v-checkbox", {
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.update_field(
                                                                    _vm.user[
                                                                      "profile_pk"
                                                                    ],
                                                                    "include_cell_phone_in_report",
                                                                    _vm.user
                                                                      .fields
                                                                      .include_cell_phone_in_report
                                                                      ? 1
                                                                      : 0
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.user
                                                                    .fields
                                                                    .include_cell_phone_in_report,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.user
                                                                      .fields,
                                                                    "include_cell_phone_in_report",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "user.fields.include_cell_phone_in_report"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticClass:
                                                          "grey darken-3 ma-0 pa-0"
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "mt-2 pt-0 pb-0 mb-0"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Economic overview in report:"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c("v-checkbox", {
                                                              model: {
                                                                value:
                                                                  _vm.app_data
                                                                    .include_overview_sections_in_report,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.app_data,
                                                                    "include_overview_sections_in_report",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "app_data.include_overview_sections_in_report"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticClass:
                                                          "grey darken-3 ma-0 pa-0"
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "mt-2 pt-0 pb-0 mb-0"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Apply default filters at startup"
                                                            ),
                                                            _c("br"),
                                                            _vm._v(
                                                              "(the current default is entry " +
                                                                _vm._s(
                                                                  _vm.startup_value_list_ix
                                                                ) +
                                                                " in the custom filter list) "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c("v-checkbox", {
                                                              on: {
                                                                change:
                                                                  _vm.set_startup_filters_state
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.load_with_filters,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.load_with_filters = $$v
                                                                },
                                                                expression:
                                                                  "load_with_filters"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticClass:
                                                          "grey darken-3 ma-0 pa-0"
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "mt-2 pt-0 pb-0 mb-0"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Update filters"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c("v-checkbox", {
                                                              model: {
                                                                value:
                                                                  _vm.$store
                                                                    .state.app
                                                                    .update_filters_selection_on_each_call,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.$store
                                                                      .state
                                                                      .app,
                                                                    "update_filters_selection_on_each_call",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "$store.state.app.update_filters_selection_on_each_call"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _c("tr", [
                                                    _c("td", [
                                                      _vm._v(_vm._s(item.item))
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(_vm._s(item.value))
                                                    ])
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3198368128
                                    )
                                  }),
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "trade-blue-text justify-start mt-0 mb-0 ml-0 pl-0"
                                    },
                                    [_vm._v("Application setttings ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "outline mt-0 pb-2 pl-2 pr-2"
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-autocomplete", {
                                            staticClass:
                                              "pr-1 font-weight-light mt-3",
                                            attrs: {
                                              items: _vm.display_magnitude,
                                              "background-color": "white",
                                              color: "green",
                                              dense: "",
                                              "hide-details": "true",
                                              "item-color": "green",
                                              label:
                                                "Default Display Magnitude",
                                              outlined: "",
                                              "prepend-icon": "mdi-decimal"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.set_magnitude($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.magnitude_setting,
                                              callback: function($$v) {
                                                _vm.magnitude_setting = $$v
                                              },
                                              expression: "magnitude_setting"
                                            }
                                          }),
                                          _c("v-autocomplete", {
                                            staticClass:
                                              "pr-1 font-weight-light mt-3",
                                            attrs: {
                                              items: _vm.get_exchange_rate,
                                              "background-color": "white",
                                              color: "green",
                                              dense: "",
                                              "hide-details": "true",
                                              "item-color": "green",
                                              "item-text": "CurrencyName",
                                              label: "Default Display Currency",
                                              outlined: "",
                                              "prepend-icon":
                                                "mdi-currency-usd",
                                              "return-object": ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.set_currency($event)
                                              }
                                            },
                                            model: {
                                              value: _vm.currency_setting,
                                              callback: function($$v) {
                                                _vm.currency_setting = $$v
                                              },
                                              expression: "currency_setting"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-0",
                                          attrs: { block: "", dark: "" },
                                          on: { click: _vm.clearStorage }
                                        },
                                        [_vm._v("Clear storage and reload ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: 2000, color: "green lighten-1", top: "" },
              model: {
                value: _vm.email_clicked,
                callback: function($$v) {
                  _vm.email_clicked = $$v
                },
                expression: "email_clicked"
              }
            },
            [
              _c(
                "h3",
                { staticClass: "text-center pa-2 black--text lighten-2" },
                [
                  _vm._v(
                    " PLease wait for your email program to open with a pre-populated message "
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }