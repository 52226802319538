import Swal from 'sweetalert2';

const ERRORS = {
  backend_dead: {
    message:
      '<div style="font-family: Roboto">We could not communicate with the Application Server.</div>',
    remedy:
      'This could mean that: ' +
      '<div style="margin-top: 9px; margin-left: 6px"><span> 1) Most probably your institutional firewall is blocking access - please contact your internal IT support.,</span></div>' +
      '<div style="margin-top: 9px; margin-left: 6px"><span> 2) Our server could be down, but we would know about it and alert you if this was the case.</span></div>',
    help_link: 'https://ta.co.za/wp/help#backend_dead',
  },
  auth_error: {
    message: 'The username and password combination for this user is not valid.',
    remedy: 'Please try again or contact your administrator.',
    help_link: 1,
  },
  html_to_xls_download_error: {
    message:
      'There was an error generating the Excel report from the current HTML table.',
    remedy: 'Please try again or contact your administrator.',
    help_link: '',
  },
  filters_selection_update_error: {
    message: 'There was an error in the function update_filter_selection.',
    remedy: 'Please try again or contact your administrator.',
    help_link: '',
  },
};

export function handle_error(error, swal2Params = {}) {
  let error_number = error.code;

  let error_str =
    '<div style="background: #e7eaee; padding: 10px; border-radius: 10px;">' +
    '<h4 style="font-family: Roboto;text-align: left; margin-bottom: 5px; padding-bottom: 5px; ">';
  error_str += ERRORS[error_number].message + '<br>';
  error_str += ERRORS[error_number].remedy + '</br>';
  error_str +=
    '<div style="margin-top: 4px; text-align: end">(' + error.message + ')</div>';
  error_str += '</h4></div>';

  Swal.fire({
    title: "<h5 style='font-family:Roboto; margin-bottom: 10px'>" + 'ERROR' + '</h5>',
    html: error_str,
    icon: 'error',
    confirmButtonText: '<div  style="font-family: ">OK</div>',
    confirmButtonColor: '#1f3c5f',
    // footer: '<a  style="font-family: Arial" href="">Get help with this issue</a>',
    width: '62em',
    customClass: {},
    ...swal2Params,
  });
}
