var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "elevation-1 grey lighten-3",
      attrs: { app: "", "clipped-left": "", dense: "" }
    },
    [
      _c("v-img", {
        attrs: {
          contain: "",
          "max-height": "100",
          "max-width": "75",
          src: require("../../assets/img/application/" +
            this.$store.state.user.userServerInfo.user.fields.institution[0]
              .fields.application_image)
        },
        on: { click: _vm.update_results_cube }
      }),
      _c(
        "span",
        {
          staticClass: "caption d-none d-md-flex ml-4 ",
          on: { click: _vm.load_state }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$store.state.user.userServerInfo.user.fields.institution[0]
                  .fields.institution_name
              ) +
              " "
          )
        ]
      ),
      (_vm.user_apps.length > 1 &&
        _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
          .markets_count > 0) ||
      _vm.$store.state.tradeDSM.dsm_filter_setttings.Filters.META.combinations
        .products_count > 0
        ? [
            _vm.user.fields.user[0] === "tobie" ||
            _vm.user.fields.user[0] === "martin"
              ? [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "span",
                                  _vm._g({}, on),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "caption ma-0 pa-0",
                                        attrs: {
                                          text: "",
                                          "x-small": "",
                                          disabled:
                                            _vm.app.selected_application ===
                                            _vm.$store.state.app
                                              .available_applications[0].value
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.change_application(
                                              _vm.$store.state.app
                                                .available_applications[0].value
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$store.state.app
                                              .available_applications[0].text
                                          ) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4213167796
                      )
                    },
                    [_c("span", [_vm._v("Export Market Finder")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "span",
                                  _vm._g({}, on),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "caption ma-0 pa-0",
                                        attrs: {
                                          text: "",
                                          "x-small": "",
                                          disabled:
                                            _vm.app.selected_application ===
                                            _vm.$store.state.app
                                              .available_applications[1].value
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.change_application(
                                              _vm.$store.state.app
                                                .available_applications[1].value
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$store.state.app
                                              .available_applications[1].text
                                          ) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3005297813
                      )
                    },
                    [_c("span", [_vm._v("Exporter Engagement Process")])]
                  )
                ]
              : _vm._e(),
            _c("v-spacer"),
            _c("v-spacer"),
            _c("v-spacer"),
            _c("v-spacer"),
            _c("span", { staticClass: "caption font-weight-bold grey--text" }, [
              _vm._v(_vm._s(_vm.selected_app_text))
            ])
          ]
        : _vm._e(),
      _c("v-spacer"),
      _c("v-spacer"),
      _c("v-spacer"),
      _c("v-spacer"),
      _c("v-spacer"),
      _c("v-spacer"),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  !_vm.logged_in
                    ? _c(
                        "v-btn",
                        _vm._g({ attrs: { icon: "" } }, on),
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "red--text rounded-circle",
                              on: {
                                click: function($event) {
                                  _vm.login_dialog = !_vm.login_dialog
                                }
                              }
                            },
                            [_vm._v("mdi-account-alert-outline ")]
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-btn",
                        _vm._g({ attrs: { icon: "" } }, on),
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "white--text orange rounded-circle ",
                              on: { click: _vm.logout_app }
                            },
                            [_vm._v("mdi-account-check ")]
                          )
                        ],
                        1
                      )
                ]
              }
            }
          ])
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(
                !_vm.logged_in
                  ? "Login"
                  : "Logout " +
                      _vm.$store.state.user.userServerInfo.user.fields.user[0]
              )
            )
          ])
        ]
      ),
      _c(
        "v-btn",
        { attrs: { icon: "" } },
        [
          _c(
            "v-icon",
            {
              staticClass: "white--text orange rounded-circle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.app.drawerRight = !_vm.app.drawerRight
                }
              }
            },
            [_vm._v("mdi-menu ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }