var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app",
        { attrs: { id: "app" } },
        [
          _vm.$store.state.user.logged_in ? _c("navigation-bar") : _vm._e(),
          _c("rightHandDrawer"),
          _c("router-view"),
          _vm.$store.state.user.logged_in ? _c("footerBar") : _vm._e(),
          _c(
            _vm.$store.state.app.activeDialogComponent,
            _vm._b(
              { tag: "component" },
              "component",
              _vm.$store.state.app.activeDialogComponentProps,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }